import React from "react";
import { graphql } from 'gatsby'
// import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/Layout";
import Hero404 from "../components/Hero/Hero404"
import ButtonSolid from '../components/Button/ButtonSolid';

export const ErrorPage = ({data }) => {

  const images = [
    data.heroDesktop.childImageSharp.fixed,
    {
    ...data.heroMobile.childImageSharp.fixed,
    media: `(max-width: 767px)`,
    },
  ]

  return (
    <Layout>

      <Hero404 sources={images}>
            <p className="heading-one">That page can’t be found.</p>
            <p>The page you were looking for appears to have been moved, deleted or does not exist. Please use the navigation above or return to the home page.</p>
            <ButtonSolid href="/" text="Back To Homepage"/>
      </Hero404>

    </Layout>
  );
}

export default ErrorPage;


export const pageQuery = graphql`
{
  heroDesktop: file(relativePath: {eq: "Home/Hero-Desktop.jpg"}) {
    childImageSharp {
      fixed(width: 1440, height: 660, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  heroMobile: file(relativePath: {eq: "Home/Hero-Desktop.jpg"}) {
    childImageSharp {
      fixed(width: 750, height: 1120, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
}
`