import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import variables from '../../sass/variables.module.scss'


const Hero = ({sources, children, className}) => (

  <BackgroundImage className={className} fixed={sources}>
    <div className="grid-container">
        <header>
            {children}
        </header>
    </div>
  </BackgroundImage>
  
)

const StyledHero = styled(Hero)`
    display: flex !important;
    max-height: 800px;
    height: 80vw;
    width: 100%;
    align-items: center;
    @media (max-width: 959px) {
        align-items: center;
    }
    @media (max-width: 767px) {
        max-height: 600px;
        height: 120vw;
    } 
    header {
        max-width: 640px;
        padding: 40px 45px 45px;
        background-color: ${variables.colorWhite};
    }
`
export default StyledHero

